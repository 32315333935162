// Подключение свайпера
import { auto } from '@popperjs/core';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
const swiper = new Swiper('.certificates__slider', {
  slidesPerView: 'auto',
  slidesPerView: 2,
  spaceBetween: 30,
  loop: true,
  // initialSlide: 1,
  navigation: {
    nextEl: '.slider__slider-btn--next',
    prevEl: '.slider__slider-btn--prev',
  },
  centeredSlides: true,
  breakpoints: {
    // when window width is >= 320px
    551: {
      slidesPerView: 3,
    },
  }
});

Swiper.use([Navigation, Pagination]);
const swiperReviews = new Swiper('.reviews__slider', {
  slidesPerView: 'auto',
  slidesPerView: 3,
  spaceBetween: 13,
  loop: true,
  // initialSlide: 1,
  slidesPerView: 1,
  navigation: {
    nextEl: '.reviews__slider-btn--next',
    prevEl: '.reviews__slider-btn--prev',
  },
  breakpoints: {
    // when window width is >= 320px
    551: {
      slidesPerView: 2,
    },
    769: {
      slidesPerView: 3,
    },

  }
});
