import { validateForms } from '../functions/validate-forms';

const rules = [
  // {
  //   ruleSelector: '.form__item-input--text',
  //   rules: [
  //     // {
  //     //   rule: 'minLength',
  //     //   value: 3
  //     // },
  //     {
  //       rule: 'required',
  //       value: true,
  //       errorMessage: 'Заполните имя!'
  //     }
  //   ]
  // },
  {
    ruleSelector: '.form__item-input--tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  }
];

const rules2 = [
  {
    ruleSelector: '.form__item-input--tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  }
];

const afterForm = () => {
  console.log('Произошла отправка!');
};

validateForms('.modal-form', rules2, afterForm);

