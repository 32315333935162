// открываем модальное окно
function openModal(){
  const btnConsult = document.querySelectorAll('.btn-consult');
  const modalCall = document.querySelector('.modal-call');
  const modalClose = document.querySelectorAll('.modal__close');
  const modal = document.querySelector('.modal');

  btnConsult.forEach(elem => {
    elem.addEventListener('click', () => {
      modalCall.classList.add('active');
    })
  });

  modalClose.forEach(elem => {
    elem.addEventListener('click', () => {
      modalCall.classList.remove('active');
    })
  });

  modal.addEventListener('click', (event) => {
    let target = event.target;
    if(target.matches(".modal")) {
      modalCall.classList.remove('active');
    }
  })
}
openModal();



// скролл вверх
document.getElementById('scrollBtn').addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' }); // Магия с помощью одной кнопки!
});
const smoothLinks = document.querySelectorAll('a[href^="#"]');
for (let smoothLink of smoothLinks) {
    smoothLink.addEventListener('click', function (e) {
        e.preventDefault();
        const id = smoothLink.getAttribute('href');

        document.querySelector(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
};

// открываем сщц сети
function openSocial() {
  const socialClose = document.querySelector('.social__btn-close');
  const socialOpen = document.querySelector('.social__btn-open');
  const socialUp = document.querySelector('.social__up');

  socialOpen.addEventListener('click', () => {
    socialUp.classList.add('active');
  })

  socialClose.addEventListener('click', () => {
    socialUp.classList.remove('active');
  })
}
openSocial()

//текущая ссылка меню выделить
const headerNavItem = document.querySelectorAll('.header__nav-item');
const headerNavLink = document.querySelectorAll('.header__nav-item a');

// headerNavLink.forEach(link => {
//   let currentUrl = window.location.pathname;

//   if (currentUrl.indexOf(link.getAttribute("href")) != -1) {
//     link.classList.add("active");
//   } else if (currentUrl === "/") {
//     let parents = document.querySelectorAll('.header__nav-list');
//     parents.forEach(item => {
//       let link = item.firstElementChild;
//       let headerLinkFirst = link.querySelector('a');
//       headerLinkFirst.classList.add("active");
//   })
//   }
// })
let currentUrl = window.location.pathname;
console.log('currentUrl: ', currentUrl);

headerNavLink.forEach(link => {
  let currentUrl = window.location.pathname;


  if (currentUrl === "/") {
    let parents = document.querySelectorAll('.header__nav-list');
    parents.forEach(item => {
      let link = item.firstElementChild;
      let headerLinkFirst = link.querySelector('a');
      headerLinkFirst.classList.add("active");
  })} else if (currentUrl.indexOf(link.getAttribute("href")) != -1) {
    link.classList.add("active");
  }
})

// burger
const html = document.querySelectorAll('html');

document.querySelector('.header__burger').addEventListener('click', function() {

  html.forEach(item => {
    item.classList.add('menu-opened');
  })

  document.querySelector('.header__nav-mobile').classList.add('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.add('active');
})

document.querySelector('.header__nav-mobile--close').addEventListener('click', function() {
  document.querySelector('.header__nav-mobile').classList.remove('active');
  document.querySelector('.header__nav-mobile--wrapper').classList.remove('active');

  html.forEach(item => {
    item.classList.remove('menu-opened');
  })
})

//анимация круги
window.onload = () => {
  const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('raz');
              observer.unobserve(entry.target)
          }
      })
  }, { threshold: 0.5 })

  document.querySelectorAll('.experience__item-bg').forEach(bg => observer.observe(bg))
}

// скролл до определенного места и убрать
const upWrapper = document.querySelector('.up__wrap');
const pageHeight = document.documentElement.scrollHeight;

window.addEventListener('scroll', function () {
  if(window.scrollY > 750) {
    upWrapper.classList.add('active');
  }
  if(window.scrollY < 750) {
    upWrapper.classList.remove('active');
  }
})

// ввод только цифры
document.querySelectorAll('.modal-tel').forEach(elem => {
  elem.addEventListener('input', function (e) {
    let target = e.target;
    target.value = target.value.replace(/[^+\d]/g, '');
  });
})







